




import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router/types/router";

import { Meta } from "@/component-decorators";
import EventAddContainer from "@/modules/event/add/EventAddContainer.vue";
import { choicesStore } from "@/store";

const loadChoices = async (to: Route, from: Route, next: NavigationGuardNext) => {
  await Promise.all([
    choicesStore.loadCountries(),
    choicesStore.loadCities(),
    choicesStore.loadStyles(),
  ]);
  await next();
};

@Component({
  components: { EventAddContainer },
  beforeRouteEnter: loadChoices,
  beforeRouteUpdate: loadChoices,
})
export default class EventAddView extends Vue {
  @Meta
  info() {
    return {
      title: this.$t("events.form.addTitle"),
    };
  }
}
